import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { arrayOf, shape, string } from 'prop-types';

import formatPhoneNumber from '@/utils/formatPhoneNumber';
import { LINK_TYPE } from '@/types';
import Link from '../Link';

const Footer = ({ footer: { title, phoneNumber, socialMedia, columns } }) => (
  <Box width="100vw" height={{ lg: 555 }} bgcolor="neutral.lightest">
    <Box maxWidth={1440} px={{ xs: 5, sm: 7, lg: 5 }} m="auto">
      <Divider variant="fullWidth" />
    </Box>
    <Box
      maxWidth={1440}
      px={{ xs: 3, sm: 7, lg: 5 }}
      py={10}
      pb={6}
      m="auto"
      height={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box maxWidth={420}>
          <Typography variant="h2" gutterBottom component="p">
            {title}
          </Typography>
          <Typography variant="h3" gutterBottom color="textSecondary" component="p">
            <Link underline="none" href={`tel:${phoneNumber}`}>
              {`Call ${formatPhoneNumber(phoneNumber)}`}
            </Link>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          justifyContent={{ lg: 'space-between' }}
          ml={{ lg: 10 }}
          flexWrap={{ xs: 'wrap', lg: 'no-wrap' }}
        >
          {columns.map(({ title: columnTitle, links }) => (
            <Box
              key={columnTitle}
              flex={{ xs: '0 1 50%', sm: '0 1 33%', lg: '0 1 auto' }}
              mt={{ xs: 5, sm: 6, lg: 0 }}
              pl={{ xs: 1, sm: 0 }}
            >
              <Box>
                <Typography variant="h4" component="p" color="secondary">
                  {columnTitle}
                </Typography>
              </Box>
              {links.map(({ href, label }) => (
                <Box key={label}>
                  <Link href={href} color="textSecondary" variant="body2">
                    {label}
                  </Link>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={{ xs: 6, lg: 0 }} pb={{ xs: 6, lg: 0 }}>
        <Divider variant="fullWidth" />
        <Box
          display="flex"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={{ sm: 'space-between' }}
          mt={{ sm: 3 }}
        >
          <Box
            display="flex"
            justifyContent={{ xs: 'space-evenly', sm: 'space-between' }}
            mt={{ xs: 3, sm: 0 }}
            width={{ sm: 250 }}
          >
            <Typography variant="caption" gutterBottom color="textSecondary">
              © {new Date().getFullYear()} Bellhop, Inc
            </Typography>
            <Link href="/terms-of-service/" underline="none" color="textSecondary" variant="caption" gutterBottom>
              Terms
            </Link>
            <Link href="/privacy-policy/" underline="none" color="textSecondary" variant="caption" gutterBottom>
              Privacy
            </Link>
          </Box>
          <Box display="flex" justifyContent={{ xs: 'space-evenly' }} mt={{ xs: 4, sm: 0 }}>
            {socialMedia.map(item => (
              <Link key={item.alt} href={item.href} rel="noreferrer">
                <img height={24} width={40} src={item.url} alt={item.alt} loading="lazy" />
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

Footer.propTypes = {
  footer: shape({
    title: string.isRequired,
    phoneNumber: string.isRequired,
    socialMedia: arrayOf(
      shape({
        alt: string.isRequired,
        href: string.isRequired,
        url: string.isRequired,
      }),
    ).isRequired,
    columns: arrayOf(
      shape({
        title: string.isRequired,
        links: arrayOf(LINK_TYPE).isRequired,
      }),
    ),
  }).isRequired,
};

export default Footer;
